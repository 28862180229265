<template>
  <div style="position: relative">
    <van-button
      type="primary"
      native-type="button"
      style="background: blue"
      @click="toDetail"
      >搜索</van-button
    >
    <div id="container" style="width: 100%; height: 50vh"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { unLatLng } from "@/api/map";

export default {
  data() {
    return {
      MAP: null,
      geolocation: null,
      marker: undefined,
      target: undefined,
      latitude: undefined,
      longitude: undefined,
    };
  },
  props: {
    locationData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.geolocation = new qq.maps.Geolocation(
      "LCJBZ-KMU63-QJH3C-3TTPS-4KQQ3-WZFIP",
      "企业认领"
    );

    this.getMyLocation();

    var myLatlng = new qq.maps.LatLng(39.916527, 116.397128);
    var myOptions = {
      zoom: 12, //设置地图缩放级别
      center: myLatlng, //设置中心点样式
      mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      // subkey: "LCJBZ-KMU63-QJH3C-3TTPS-4KQQ3-WZFIP",
    };

    this.MAP = new qq.maps.Map(document.getElementById("container"), myOptions);

    // // let anchor = new qq.maps.Point(6, 6),
    // //   size = new qq.maps.Size(24, 24),
    // //   origin = new qq.maps.Point(0, 0),
    // //   icon = new qq.maps.MarkerImage(
    // //     "https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/center.gif",
    // //     size,
    // //     origin,
    // //     anchor
    // //   );

    // this.init();
  },
  watch: {
    locationData: {
      handler(val) {
        this.marker && this.marker.setMap(null);
        this.init();
      },
      // immediate: true,
      deep: true,
    },
  },
  methods: {
    toDetail() {
      this.$emit("search");
    },
    showPosition(position) {
      console.log(position, "position");
      this.latitude = position.lat;
      this.longitude = position.lng;
      let city = position.city;
      Toast.clear();
      this.init(this.latitude, this.longitude);
    },
    showErr() {
      // console.log("定位失败");
      this.getMyLocation(); //定位失败再请求定位，测试使用
    },
    //定位获得当前位置信息
    getMyLocation() {
      this.geolocation.getLocation(this.showPosition, this.showErr); //或者用getLocation精确度比较高
    },
    init(lat, long) {
      // console.log("🚀 ~ file: map.vue ~ line 64 ~ init ~ lat, long", lat, long);
      let that = this;
      if (
        this.locationData.lat.length != 0 ||
        this.locationData.lng.length != 0
      ) {
        this.MAP.panTo(
          new qq.maps.LatLng(this.locationData.lat, this.locationData.lng)
        );
        this.marker = new qq.maps.Marker({
          position: new qq.maps.LatLng(
            this.locationData.lat,
            this.locationData.lng
          ),
          map: this.MAP,
        });
      } else {
        this.MAP.panTo(new qq.maps.LatLng(lat, long));
      }

      // 逆地址解析(经纬度到地名转换过程)
      //   new qq.maps.Geocoder({
      //     complete: function (res) {
      //       console.log(res);
      //     },
      //   });

      // if (JSON.stringify(this.locationData) != { lat: "", lng: "" }) {
      //   this.marker = new qq.maps.Marker({
      //     position: new qq.maps.LatLng(
      //       this.locationData.lat,
      //       this.locationData.lng
      //     ),
      //     map: this.MAP,
      //   });
      // }

      qq.maps.event.addListener(this.MAP, "click", function (e) {
        unLatLng({
          location: e.latLng.lat + "," + e.latLng.lng,
          key: "LCJBZ-KMU63-QJH3C-3TTPS-4KQQ3-WZFIP",
        }).then((res) => {
          if (res.status == 0) {
            that.$emit("location", res);
          }
        });
        that.target = e;
        if (!that.marker) {
          that.marker = new qq.maps.Marker({
            position: e.latLng,
            map: that.MAP,
          });
          return;
        }

        that.marker.setPosition(e.latLng);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}
/deep/.smnoprint {
  display: none;
}
/deep/.amap-logo {
  display: none;
  opacity: 0 !important;
}
/deep/.amap-copyright {
  opacity: 0;
}
/deep/#container > div:nth-of-type(1) > div:nth-of-type(2) {
  display: none;
}
</style>
