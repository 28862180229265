import request from "@/utils/request.js";

//根据地址获取经纬度
export function latLng(params) {
  return request({
    method: "get",
    url: "/latLng",
    params,
  });
}

//根据经纬度获取地址
export function unLatLng(params) {
  return request({
    method: "get",
    url: "/unLatLng",
    params,
  });
}
